var $ = jQuery;

jQuery('.featured-listing').slick({
  slidesToShow: 3,
  slidesToScroll: 1,
  arrows: true,
    nextArrow: '.fl-arrow.next',
    prevArrow: '.fl-arrow.prev',
    adaptiveHeight: true,
    rows: 0,
    swipeToSlide: true,
    infinite: true,
    responsive: [
                    //1024
                    {
                        breakpoint: 1024,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                        }
                    },
                    //786px
                    {
                        breakpoint: 786,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    },
                    //600px
                    {
                        breakpoint: 600,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    },
                    //480px
                    {
                        breakpoint: 480,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1
                        }
                    }
                 ]

});

/***details page js ***/




